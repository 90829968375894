<div class="header-box fixed-top">
  <div class="vectorOnHeaderBox">
    <div class="row">
      <div class="col-xs-2 col-2" (click)="backClicked()" style="line-height: 30px">
        <img src="/assets/BackIcon.png" height="20" />
      </div>
      <div class="col-xs-8 col-8 page-header">เปิดบัตร Play</div>
      <div class="col-xs-2 col-2"></div>
    </div>
  </div>
</div>
<div class="leftCorner"><img src="/assets/LeftCorner.png" height="12" /></div>
<div class="rightCorner"><img src="/assets/RightCorner.png" height="12" /></div>

<div class="pageBody">
  <div class="pad16">
    <img src="/assets/PaotangPayPlay.png" class="card-Image" />
    <p class="note-Heading">กรุณากรอกข้อมูลบนหลังบัตร Play ที่คุณต้องการเปิด</p>
    <mat-divider></mat-divider>
    <form [formGroup]="demoForm" (ngSubmit)="onSubmit(demoForm)" autocomplete="off" novalidate>
      <ng-template class="tooltipBox" #popTemp>
        <div>
          <p class="tooltipText">หมายเลขบัตร 16 หลักจะ ปรากฏบนหลังบัตรของคุณ</p>
          <p #ttButton class="tooltipText tooltipLinkButton" (click)="tooltipTrigger.hide()">
            ปิด
          </p>
        </div>
      </ng-template>

      <div style="display: block; margin-top: 24px; margin-bottom: 16px">
        <span class="formHeaders">หมายเลขบัตร 16 หลัก &nbsp;</span>
        <div style="display: inline" [tooltip]="popTemp" #tooltipTrigger="bs-tooltip" placement="bottom" container="body" (onShown)="inpCCNum.blur()">
          <img (click)="tooltipTrigger.show()" src="/assets/ic_info_grey.png" height="16" style="vertical-align: text-top" />
        </div>
      </div>

      <div class="form-group">
        <input #inpCCNum (blur)="onBlur()" (focus)="onFocus()" id="cc-number" maxlength="19" minlength="19" formControlName="creditCard" type="tel" inputmode="numeric" class="inputStyle" autocomplete="cc-number" placeholder="กรอกหมายเลขบัตร 16 หลัก" appCcFormat (blur)="CheckifValidCC()" [ngClass]="{ inputStyleError: validCC }" (copy)="disableCutCopy($event)" (cut)="disableCutCopy($event)" (paste)="
            $event.stopImmediatePropagation();
            $event.stopPropagation();
            $event.preventDefault()
          " (input)="onInput($event, 'creditCard')" (focusin)="onFocusInBtnCC()" (focusout)="onFocusOutBtnCC()" />
        <div *ngIf="isCCError" class="ccErrors">
          หมายเลขบัตรไม่ถูกต้องหรือไม่ครบ 16 หลัก
        </div>
      </div>
      <p class="formHeaders">วันหมดอายุบัตร</p>
      <div class="form-group">
        <input #expireInput (blur)="onBlur()" (focus)="onFocus()" id="cc-exp" maxlength="7" formControlName="expDate" type="tel" style="width: 50%" class="inputStyle cc-exp" autocomplete="cc-exp" placeholder="ดด / ปป" ccExp (blur)="CheckifValidExp()" [ngClass]="{ inputStyleError: validExp }" (copy)="disableCutCopy($event)" (cut)="disableCutCopy($event)" (paste)="$event.preventDefault()" (focusin)="onFocusInBtnExp()" (focusout)="onFocusOutBtnExp()" />
        <div *ngIf="isExpError" class="ccErrors">
          {{ expErrorText }}
        </div>
      </div>

      <!-- <div class="errors"></div> -->
      <mat-divider style="margin-top: 16px"> </mat-divider>
      <p class="formHeaders">ชื่อเรียกบัตรที่จะแสดงบนแอปฯ</p>
      <div class="form-group">
        <input (blur)="onBlur()" (focus)="onFocus()" id="cc-name" formControlName="cardNickName" class="inputStyle" placeholder="กรอกชื่อเรียกบัตร" [(ngModel)]="cardNickName" (blur)="CheckifValidNickName()" [ngClass]="{ inputStyleError: validNN }" (keypress)="keypressEmojiStrip($event)" (input)="keypressEmojiStripInput($event)" (paste)="
            $event.stopImmediatePropagation();
            $event.stopPropagation();
            $event.preventDefault()
          " (copy)="disableCutCopy($event)" (cut)="disableCutCopy($event)" (focusin)="onFocusInBtnNickName()" (focusout)="onFocusOutBtnNickName()" maxlength="20" />
      </div>
      <div *ngIf="isNickNameError" class="ccErrors">
        กรุณากรอกชื่อเรียกบัตรที่จะแสดงบนแอปฯ
      </div>
      <p class="charCount">{{ getLenNickName }}/20 ตัวอักษร</p>
      <!-- cardLookupResponse -->
      <div>
      {{cardLookupResponse | json}}
      </div>
      <div class="form-group">
        <button class="btn submitStyle" [disabled]="!demoForm.valid" (click)="onSubmit(demoForm); onSendGA()" [style]="safeBottom">
          ถัดไป
        </button>
      </div>
    </form>
  </div>
</div>